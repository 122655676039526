import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/offer.module.css"
import SEO from "../../components/seo"

const missionFemale = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot Mission Female" />
      <StyledHero img={data.missionFemaleBcg.childImageSharp.fluid} />
      <section className={styles.offer}>
        <Title title="Leistungsangebot" subtitle="Mission Female" />
        <div className={styles.offerCenter}>
          <header>
            <a
              href="https://www.missionfemale.com/#mission"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/mission_female.png"
                alt="Mission Female Logo"
              />
            </a>
            <h4>Pro Bono Leistungsangebot Mission Female</h4>
          </header>
          <article className={styles.offerText}>
            <p>
              Mission Female ist ein branchenübergreifendes Netzwerk für Top
              Managerinnen im deutschsprachigen Raum. Unter dem Motto
              #strongertogether werden Führungsfrauen in einem exklusiven und
              verbindlichen Rahmen persönlich zusammengebracht. Ob hochkrätige
              Workshops im Bereich Female Leadership, Dinner Veranstaltungen,
              moderierte Peer-Talks oder das jährliche Offsite - hier geht es
              darum, sich gegenseitig im Beruf noch erfolgreicher und sichtbarer
              zu machen.
            </p>
            <p>
              Mission Female wurde Anfang 2019 von Frederike Probert gegründet.
              In den vergangenen 15 Jahren machte sie sich als erfolgreiche
              Unternehmerin in der digitalen Industrie und als Expertin für
              Gender Diversity Themen einen Namen. Ihr erklärtes Ziel ist es,
              Frauen in Wirtschaft, Kultur und Politik zu stärken. Ihr erstes
              Buch zu diesem Thema erscheint im Sommer 2020.
            </p>
            <p>
              Über Mission Female bietet Frederike Probert Zugang zu
              Entscheiderinnen in Unternehmen, um ihr Projekt, Produkt und
              Dienstleistung relevant und ohne Zeitverzug zu platzieren.
            </p>
          </article>
          <footer>
            <a
              href="mailto:contact@missionfemale.com"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Kontaktieren Sie uns
            </a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    missionFemaleBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default missionFemale
